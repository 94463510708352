import React from "react"
import LatestJobs from "../components/Jobs/LatestJobs"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HomeImageHero from "../components/Home/ImageHero"

const IndexPage = ({uri, location}) => (
  <>
    <HomeImageHero />
    <Layout page="home">
      <SEO title="Home" />
      <div className="mb-2">
        <div style={{ maxWidth: `1260px`, position:"relative", color:"#fff", marginBottom: `1.45rem` }}>
          <LatestJobs uri={uri} location={location} />
        </div>
      </div>
      
    </Layout>
  </>
)

export default IndexPage
