import React, { Component } from 'react';
import { navigate, Link  } from 'gatsby'
import JobList from "../Jobs/VerticalGridLayout"
import { getLatestJobs } from '../../actions/jobs'
import { FaSearch } from "react-icons/fa"
import ContentLoaderJobGrid from "../Loader/ContentLoaderJobGrid"
import { getAutoCompletionProv } from "../../actions/jobs"
import Autocomplete from "react-autocomplete"

class LatestJobs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cities:[],
            city:'',
            args:{
                search_location:'',
                search_keyword:'',
                offset:0,
                jumPage:24,
            },
            page:1,
            forcePageOpen:false,
            data:null,
            totalRecords:0,
            loading:false,
            onFilter:false,
            fromPage:"job"
        }
    }

    componentDidMount() {
        this.onLatest({ loading:true })
        this.setState({fromPage:"home"})
    }

    onLatest(args) {
        this.setState(args, ()=> {
            getLatestJobs().then(res=>{
                const { data } = res
                this.setState({
                    loading:false,
                    data:data,
                    totalRecords:24
                })

                
            }).catch(err=>{
                this.setState({
                    loading:false,
                })
            })
        })
    }

    onChange(e) {
        this.setState({
            args:{
                ...this.state.args,
                [e.target.name]:e.target.value
            }
        })
    }

    onClick() {
        navigate(
            "/job/",
            {
                state: { 
                    job_title:this.state.args.search_keyword,
                    location:this.state.args.search_location,
                    city:this.state.city
                },
            }
          )
    }

    onRenderCity(e) {
        let params = {city:e.target.value}
        this.setState(params)

        if( e.target.value == '' ) {
            this.setState({
                args:{
                    ...this.state.args,
                    search_location:''
                }
            })
        }

        if( e.target.value.length > 0 ) {
            getAutoCompletionProv(e.target.value)
                .then(res=>{
                    this.setState({ cities: res })
                })
        }
        
    }

    render() {
        let data = this.state.data
       
        return (
            <div className="filterBox">
                <div className="wrapperBoxFilter" style={{
                    textAlign:"center",
                    justifyContent:"center"
                }}>
                    <h1 style={{fontSize:50, fontWeight:900}} className="d-none mb-4">Find Your Migas Job Here</h1>
                    <form className="form-inline py-3" style={{justifyContent:"center"}}>
                        <div className="input-group">

                            <input type="text" 
                                name="search_keyword" 
                                value={this.state.args.search_keyword} 
                                onChange={this.onChange.bind(this)} 
                                className="job-input form-control form-control-lg" 
                                placeholder="Job title" 
                                id="" />

                            <Autocomplete
                                
                                inputProps={{
                                    type:"text",
                                    placeholder:"Work location",
                                    style:{
                                        borderRadius:0
                                    },
                                    className:"form-control form-control-lg" 
                                }}
                                wrapperProps={{
                                    className:"autocompletion"
                                }}
                                getItemValue={(item) => item.name}
                                items={this.state.cities}
                                renderMenu={children => (
                                    <div className="menu" style={{textAlign:"left", color:"#333"}}>
                                    {children}
                                    </div>
                                )}
                                renderItem={(item, isHighlighted) =>
                                    <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                                        {item.name}
                                    </div>
                                }
                                value={this.state.city}
                                onChange={this.onRenderCity.bind(this)}
                                onSelect={(value, item) => {
                                    // set the menu to only the selected item
                                    this.setState({ city:value, args: {
                                        ...this.state.args,
                                        search_location:item.code
                                    } })
                                }}
                            />
                            
                            <div className="input-group-append" style={{height:47}}>
                                <button className="btn btn-danger px-4" type="button" onClick={this.onClick.bind(this)} id="button-addon2">
                                    <FaSearch size={24} />
                                </button>
                            </div>
                        </div>
                    </form>
                </div>

                <div className="description my-3 mx-2 text-center">
                    <p style={{fontSize:17}}>Hati-hati terhadap telepon dari pihak memakai nama jobsmigas untuk tawarkan interview pekerjaan. Jobsmigas tidak pernah meminta uang untuk ongkos tiket pesawat dan akomodasi kepada pencari kerja segera hubungi Kami di info@jobsmigas.com </p>
                </div>
                
                {this.state.loading && <ContentLoaderJobGrid count={15} />}
                {!this.state.loading && data && 
                    <>
                        <JobList height={400} items={data} />
                        <div className="text-center">
                            <Link to="/job?page=2" className="mt-0 mb-3 btn btn-dark btn-lg">View More</Link>
                        </div>
                        
                    </>
                }
                
            </div>
        )
    }
}

LatestJobs.defaultProps = {
    items:null
}


export default LatestJobs