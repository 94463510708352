import React from "react"
import { kebabCase } from "lodash"
import { Link } from "gatsby"
import { displayHumanDate } from "../../utils/helpers"
import moment from "moment"
import { FaMapMarked, FaHourglassHalf } from "react-icons/fa"

const JobsVerticalGridLayout = (props) => {
    const { items, title='Latest Jobs', height=360, noImage=false } = props
    return (<div style={{ margin: '2rem auto' }}>
      <ul className="row justify-content-center" style={{ padding: 0 }}>
        {items.map(item => (
          <li
            key={item.job_id}
            className={"col-12 col-lg-4 mb-5"}
            style={{
              height:height,
              float:"left",
              listStyle: 'none',
            }}
          >
              <div className="shadow card mb-3" style={{height:height, color:'#333'}}>
                <div className="row no-gutters">
                    {noImage == false && <div style={{height:(height/3)}} className="col-md-12 d-flex border-bottom align-items-center justify-content-center">
                        {item.is_closed && <div style={{
                            position:"absolute",
                            height:"100%",
                            width:"100%",
                            zIndex:1000,
                            background:"rgba(0,0,0,0.5)",
                            alignContent:"center",
                            justifyContent:"center",
                            top:0,
                            left:0,
                            bottom:0,
                            right:0
                        }}>
                          <div style={{
                            display:"flex",
                            alignContent:"center",
                            alignItems:"center",
                            justifyContent:"center",
                            color:"#fff",
                            fontWeight:"bold",
                            fontSize:24,
                            height:"100%",
                            textAlign:"center",

                          }}>
                            CLOSED
                          </div>
                        </div>}
                        <img src={item.employers_logo} style={{maxWidth:"100%", maxHeight:((height/3)-2)}} />
                    </div>}
                    <div className="col-md-12">
                        <div className="card-body bg-light" style={{height:parseInt(height/2)+65}}>
                            <h4 className="card-title mb-2" style={{
                              fontSize:(item.job_title.length > 40 ? 20 : '1.38rem')
                            }}>{item.job_title} {item.is_closed && <span className="badge badge-pill badge-secondary" style={{fontSize:'0.86rem'}}>Closed</span>} </h4>

                            <h5 className="mb-2 display-5" style={{fontSize:`1.1rem`, color:'#0F1698'}}>
                              {item.employers_company_name}
                            </h5>

                            <p className="mb-1 text-dark" style={{fontSize:`0.9rem`}}>
                              <FaMapMarked size={12} /> {item.peropinsi ? item.peropinsi.lokasi_nama : ''}
                            </p>

                            <p className="mb-3" style={{fontSize:`0.9rem`, color:'#777'}}>
                                <FaHourglassHalf size={12} color={"#777"} /> Deadline date <strong>{moment(item.job_finish_date).format("DD MMM YYYY")}</strong>
                            </p>

                            <h5 className="mb-2">
                              {item.job_type_id && <span className="badge badge-pill badge-warning">{item.job_type_obj.job_type}</span>}
                              {item.industry_type_label && <span className="badge badge-pill badge-danger">{item.industry_type_label}</span>}
                            </h5>
                            
                        </div>

                        <div className="card-footer" style={{position:"absolute", bottom:0, width:"100%"}}>
                            <div className="row">
                                <div className="col-8">
                                    <span className="text-muted" style={{fontSize:14}}>Published on {displayHumanDate(item.job_start_date)}</span>
                                </div>
                                <div className="col text-right">
                                    <Link style={{fontSize:12}} className="btn btn-md btn-outline-primary" to={`/job/detail/${item.job_id}_${kebabCase(item.job_title)}`} state={{ id: item.job_id }}>
                                        Detail
                                    </Link>
                                </div>
                            </div>
                        </div>
                      
                    </div>
                </div>
            </div>
            
          </li>
        ))}
      </ul>
    </div>)
};
  

export default JobsVerticalGridLayout